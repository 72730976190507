import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  Checkbox,
} from "@mui/material";
import "./App.css";
import HeroArea from "./HeroArea";
const Form = () => {
  const [firstName, setFirstName] = useState("");
  const [city, setCity] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("x");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [errorText, setErrorText] = useState("");
  const [state, setState] = useState("");
  const [insuranceProvider, setInsuranceProvider] = useState("");
  const [phone, setPhone] = useState("");
  const [insuranceProviderNumber, setInsuranceProviderNumber] = useState("");
  const [ID, setID] = useState("");
  const [disclaimer, setDisclaimer] = useState(false);
  const [error, setError] = useState(false);
  const [signature, setSignature] = useState("");
  const [zip, setZip] = useState("");
  const [success, setSuccess] = useState(false);
  const [site, setSite] = useState("");
  const [monthlySupplies, setMonthlySupplies] = useState(false);

  const handlePost = () => {
    const DATA = {
      username: "umair2022",
      password: "sehalabdullah",
      site_dealer: "admin",
      first_name: firstName,
      last_name: lastName,
      gender: gender,
      dob: dob,
      address: address,
      email: email,
      city: city,
      state: state,
      insurance_provider: insuranceProvider,
      insurance_provider_no: insuranceProviderNumber,
      phone_no: phone,
      // license_no: ID,
      zip_code: zip,
      signature: signature,
      site: site,
      monthly_kits: monthlySupplies,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(DATA),
    };
    // fetch("http://127.0.0.1:8000/api/addOrder", requestOptions)
    fetch("https://api.labquestdiagnostic.com/api/addOrder", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.message === "True") {
          setSuccess(true);
        } else {
          setErrorText("Something went Wrong!");
          setError(true);
        }
      })
      .catch((error) => {
        setErrorText(error);
        setError(true);
      });
  };

  const handleSubmit = () => {
    setErrorText("");
    setError(false);
    // if (site === "") {
    //   setErrorText("Please Select a Site!");
    //   setError(true);
    //   return;
    // }
    if (firstName === "") {
      setErrorText("Please Enter your First Name!");
      setError(true);
      return;
    }
    if (lastName === "") {
      setErrorText("Please Enter your Last Name!");
      setError(true);
      return;
    }
    if (gender === "") {
      setErrorText("Please Select a Gender!");
      setError(true);
      return;
    }
    if (dob === "x" || dob === "") {
      setErrorText("Please Enter your Date of Birth!");
      setError(true);
      return;
    }
    if (address === "") {
      setErrorText("Please Enter your Address");
      setError(true);
      return;
    }
    if (city === "") {
      setErrorText("Please Enter your City!");
      setError(true);
      return;
    }
    if (state === "") {
      setErrorText("Please Enter your State!");
      setError(true);
      return;
    }
    if (zip === "") {
      setErrorText("Please Enter Your Zip Code!");
      setError(true);
      return;
    }
    // if (email === "") {
    //   setErrorText("Please Enter your Email!");
    //   setError(true);
    //   return;
    // }
    if (insuranceProvider === "") {
      setErrorText("Please mention your Insurance Provier!");
      setError(true);
      return;
    }
    if (insuranceProviderNumber === "") {
      setErrorText("Please Enter your Insurance Provider Number!");
      setError(true);
      return;
    }
    // if (phone === "") {
    //   setErrorText("Please Enter your Phone Number!");
    //   setError(true);
    //   return;
    // }
    // if (ID === "") {
    //   setErrorText("Please Enter your ID!");
    //   setError(true);
    //   return;
    // }
    if (disclaimer === false) {
      setErrorText("Please check the box for consent!");
      setError(true);
      return;
    }
    if (signature === "") {
      setErrorText("Patient Signature Required!");
      setError(true);
      return;
    }
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/;
    if (email !== "") {
      if (!email.match(mailformat)) {
        setErrorText("Please Enter a Valid Email!");
        setError(true);
        return;
      }
    }
    let regex = /^\d{5}$/;
    let result = regex.test(zip);
    if (!result) {
      setErrorText("Please Enter a Valid Zip Code!");
      setError(true);
      return;
    }

    handlePost();
  };

  const handleChange = (event: SelectChangeEvent) => {
    setGender(event.target.value);
  };

  return (
    <div className="App-Form">
      <HeroArea />
      {!success ? (
        <Container
          maxWidth="sm"
          style={{ paddingTop: 25, color: "#000", paddingBottom: 60 }}
        >
          <div className="form-container">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p
                style={{
                  textDecoration: "underline",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Please provide your details to claim test kits
              </p>
            </div>
            {/* <Typography
              variant="h5"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Claim Your Test Kits
            </Typography> */}
            <p>Asterisk(*) fields are mandatory</p>
            <FormControl fullWidth style={{ marginTop: 8 }}>
              <InputLabel id="demo-simple-select-label">Site</InputLabel>
              <div style={{ display: "flex" }}>
                <Select
                  style={{ width: 150 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  placeholder="Site"
                  value={site}
                  label="Site"
                  size="small"
                  onChange={(event) => setSite(event.target.value)}
                >
                  <MenuItem value={"A"}>A</MenuItem>
                  <MenuItem value={"B"}>B</MenuItem>
                  <MenuItem value={"C"}>C</MenuItem>
                  <MenuItem value={"D"}>D</MenuItem>
                  <MenuItem value={"E"}>E</MenuItem>
                  <MenuItem value={"Geo"}>Geo</MenuItem>
                  <MenuItem value={"1500 Madison"}>1500 Madison</MenuItem>
                  <MenuItem value={"Khan"}>Khan</MenuItem>
                  <MenuItem value={"Guide post"}>Guide post</MenuItem>
                  <MenuItem value={"5041"}>5041</MenuItem>
                  <MenuItem value={"Rosemary"}>Rosemary</MenuItem>
                  <MenuItem value={"300 Damen"}>300 Damen</MenuItem>
                </Select>
                <p style={{ fontSize: 14, marginLeft: 5 }}>
                  {" "}
                  (For office use only)
                </p>
              </div>
            </FormControl>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <TextField
                required
                label="First Name"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                onBlur={() => {
                  if (firstName !== "") {
                    setMonthlySupplies(true);
                  }
                }}
                margin="normal"
                fullWidth
                size="small"
              />
              <div style={{ width: 25 }}></div>

              <TextField
                required
                label="Last Name"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                margin="normal"
                fullWidth
                size="small"
              />
            </div>
            {/* state, Insurance provider */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormControl fullWidth style={{ marginTop: 8 }}>
                <InputLabel id="demo-simple-select-label">Gender *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  required
                  placeholder="Gender"
                  value={gender}
                  label="Gender"
                  size="small"
                  onChange={handleChange}
                >
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </FormControl>
              <div style={{ width: 25 }}></div>
              <TextField
                required
                label="Date of Birth"
                type="date"
                value={dob}
                onChange={(event) => setDob(event.target.value)}
                margin="normal"
                fullWidth
                size="small"
              />
            </div>

            <TextField
              required
              label="Address"
              value={address}
              onChange={(event) => setAddress(event.target.value)}
              margin="normal"
              fullWidth
              size="small"
            />

            <div style={{ display: "flex", flexDirection: "row" }}>
              <TextField
                required
                label="City"
                value={city}
                onChange={(event) => setCity(event.target.value)}
                margin="normal"
                fullWidth
                size="small"
              />
              <div style={{ width: 25 }}></div>
              <TextField
                required
                label="State"
                value={state}
                onChange={(event) => setState(event.target.value)}
                margin="normal"
                fullWidth
                size="small"
              />
              <div style={{ width: 25 }}></div>
              <TextField
                required
                label="Zip Code"
                value={zip}
                onChange={(event) => setZip(event.target.value)}
                margin="normal"
                fullWidth
                size="small"
              />
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <TextField
                label="Email (optional)"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                margin="normal"
                fullWidth
                size="small"
              />
              <div style={{ width: 25 }}></div>
              <TextField
                label="Phone Number (optional)"
                type={"text"}
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                margin="normal"
                fullWidth
                size="small"
              />
              {/* <TextField
                required
                label="ID Number"
                value={ID}
                onChange={(event) => setID(event.target.value)}
                margin="normal"
                fullWidth
                size="small"
              /> */}
            </div>
            <div className="insurance-container">
              <p style={{ fontWeight: "bold" }}>Insurance Details:</p>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  required
                  label="Insurance Provider"
                  value={insuranceProvider}
                  onChange={(event) => {
                    if (
                      insuranceProviderNumber &&
                      event.target.value.length > 0
                    ) {
                      setMonthlySupplies(true);
                    } else {
                      setMonthlySupplies(false);
                    }
                    setInsuranceProvider(event.target.value);
                  }}
                  margin="normal"
                  fullWidth
                  size="small"
                />
                <div style={{ width: 25 }}></div>
                <TextField
                  required
                  label="Insurance Policy Number"
                  value={insuranceProviderNumber}
                  onChange={(event) => {
                    if (insuranceProvider && event.target.value.length > 0) {
                      setMonthlySupplies(true);
                    } else {
                      setMonthlySupplies(false);
                    }
                    setInsuranceProviderNumber(event.target.value);
                  }}
                  margin="normal"
                  fullWidth
                  size="small"
                />
              </div>
            </div>
            {/* <div className="insurance-container" style={{ borderTop: 0 }}>
              <p style={{ fontWeight: "bold" }}>Repeat Monthly Order:</p>
              <div style={{ display: "flex", flexDirection: "row" }}>
                Keep your loved ones safe and stay ahead of the curve by testing
                regularly. Medicare will cover up to 8 Covid-19 home tests each
                calendar month. You can cancel this recurring service at any
                time.
              </div>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Checkbox
                  onChange={(event) => setDisclaimer(event.target.value)}
                />
                <h4>
                  Yes, please send me 8 FREE tests every month. I understand I
                  can cancel at any time.
                </h4>
              </div>
            </div> */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                title="Test"
                checked={monthlySupplies}
                onChange={(event) => setMonthlySupplies(!monthlySupplies)}
              />
              {!monthlySupplies ? (
                <p style={{ fontSize: 12, textAlign: "justify" }}>
                  Please send me monthly supplies.
                </p>
              ) : (
                <p style={{ fontSize: 12, textAlign: "justify" }}>
                  Uncheck to stop monthly services.
                </p>
              )}
            </div>

            <p style={{ fontSize: 12, textAlign: "justify" }}>
              Consent: I authorize FA 1st Solutions to use my information
              included in this waivetr solely for insurance billing purpose.
            </p>
            <TextField
              required
              label="Signature"
              value={signature}
              onChange={(event) => setSignature(event.target.value)}
              margin="normal"
              size="small"
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                onChange={(event) => setDisclaimer(event.target.value)}
              />
              <p>Disclaimer</p>
            </div>

            <p style={{ textAlign: "justify" }}>
              I hereby acknowledge that I have not received any home covid test
              kits this month and that all information I have entered is true,
              accurate and best of my knowledge. FA 1st Solutions is not liable
              for any errors or omissions.
            </p>
            {error ? <p style={{ color: "red" }}>{errorText}</p> : null}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
                style={{
                  marginTop: 10,
                  marginBottom: 25,
                  backgroundColor: "#0f70e6",
                }}
              >
                Submit
              </Button>
              <Button
                type="submit"
                variant="contained"
                LinkComponent={Link}
                color="primary"
                to={"/"}
                style={{
                  backgroundColor: "#EB9495",
                  width: 200,
                  marginTop: 60,
                }}
              >
                Visit Site
              </Button>
            </div>
          </div>
        </Container>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <h3 style={{ color: "black" }}>Data Submitted Successfully!</h3>
        </div>
      )}
    </div>
  );
};

export default Form;
