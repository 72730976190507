import React from 'react'

function useWindowSize() {
    const isSSR = typeof window !== "undefined";
  const [windowSize, setWindowSize] = React.useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
  });

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    });

    return () => {
        window.removeEventListener("resize", () => {
          setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        });
      }
  }, []);

  return windowSize;
}

export default useWindowSize