import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Rnd } from "react-rnd";
import { motion } from "framer-motion";
import { components } from "ComponentRenderer.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Box from "@mui/material/Box";
import Form from "Form";
import {
  Container,
  Content2Xl,
  ContentWithVerticalPadding,
} from "components/misc/Layouts";
import { Button, Modal } from "@mui/material";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { LogoLink } from "components/headers/light.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as RadioIcon } from "feather-icons/dist/icons/radio.svg";
import { ReactComponent as HandleIcon } from "images/handle-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";

import heroScreenshotImageSrc from "images/demo/MainLandingPageHero-new.png";
import logo from "./images/demo/logo192.png";
import useInView from "helpers/useInView";
import ThreePlans from "components/pricing/ThreePlans";
import BackgroundAsImage from "components/hero/BackgroundAsImage";
import BackgroundAsImageWithCenteredContent from "components/hero/BackgroundAsImageWithCenteredContent";
import FullWidthWithImage from "components/hero/FullWidthWithImage";
import ResponsiveVideoEmbed from "helpers/ResponsiveVideoEmbed";
import SingleCol from "components/faqs/SingleCol";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";
import useWindowSize from "utils/useWindowSize";
import { Link } from "react-router-dom";
import GetStarted from "components/cta/GetStarted";
import GetStartedLight from "components/cta/GetStartedLight";
import DownloadApp from "components/cta/DownloadApp";
import { width } from "@mui/system";
import { useRef } from "react";
/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1  mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;
// const PrimaryNavLink = tw(
//   NavLink
// )`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline mt-6 md:mt-4 lg:mt-0`;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline mt-20 md:mt-4 lg:mt-0`;
const HeroRow = tw(
  Row
)`flex-col lg:flex-row justify-between items-center pt-8 lg:pt-12 pb-16 max-w-screen-2xl mx-auto flex-wrap`;
const HeroRowMain = tw(
  Row
)`flex-col lg:flex-row justify-between items-center pt-8 lg:pt-12 pb-16 max-w-screen-2xl mx-auto flex-wrap`;

const Column = tw.div`flex-1`;

const UpdateNotice = tw(
  Column
)`w-full flex-auto mb-4 sm:mb-8 rounded px-4 py-3 sm:px-5 sm:py-4 bg-orange-100 text-orange-800 flex items-center sm:items-start md:items-center justify-center lg:justify-start border border-orange-200 text-xs sm:text-sm text-center sm:text-left md:leading-none`;
const UpdateNoticeIcon = tw(RadioIcon)`w-0 sm:w-5 sm:mr-3`;

const TextColumn = tw(
  Column
)`mx-auto lg:mr-0 max-w-2xl lg:max-w-xl xl:max-w-2xl flex-shrink-0`;
const Heading = tw(
  HeadingBase
)`text-center lg:text-left text-primary-900 leading-snug`;
const Description = tw(
  DescriptionBase
)`mt-4 text-center lg:text-left lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center lg:justify-start`;
const ActionButton = tw(
  AnchorLink
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;
const PrimaryButton = tw(ActionButton)``;
const SecondaryButton = tw(
  ActionButton
)`mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;
const FeatureList = tw.ul`mt-6 leading-loose flex flex-wrap max-w-xl mx-auto lg:mx-0`;
const Feature = tw.li`mt-2 flex items-center flex-shrink-0 w-full sm:w-1/2 justify-center lg:justify-start`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`mx-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-8`;
const ImageContainer = tw.div``;
const Image = tw.img`max-w-full rounded-t sm:rounded`;

const SectionContainer = tw(ContentWithVerticalPadding)``;
const SectionHeading = tw(HeadingBase)`text-primary-900`;
const SectionDescription = tw(
  DescriptionBase
)`text-center mx-auto text-gray-600 max-w-4xl`;

const PreviewCards = tw.div`flex flex-wrap -mr-12`;
const PreviewCardContainer = tw.div`mt-24 mx-auto md:mx-0 max-w-lg w-full md:w-1/2 lg:w-1/3 pr-12`;
const PreviewCard = tw(motion.a)`block rounded-lg shadow-raised`;
const PreviewCardImageContainer = tw.div`rounded-t-lg border-0 border-b-0`;
const PreviewCardImage = styled(motion.div)`
  ${(props) =>
    css`
      background-image: url("${props.$imageSrc}");
    `}
  ${tw`h-128 md:h-144 bg-cover bg-left-top`}
`;
const PreviewButton = tw(
  PrimaryButtonBase
)`w-full rounded-b-lg rounded-t-none py-5 font-semibold`;

const ComponentsContainer = tw.div`mt-24`;
const ComponentsType = tw.h3`text-4xl font-black text-primary-500 border-b-4 border-primary-500 inline-block`;
const Components = tw.div``;
const Component = tw.div`mt-12 border rounded-lg bg-white`;
const ComponentHeading = tw.div`px-8 py-5 border-b flex flex-col sm:flex-row justify-between items-center`;
const ComponentName = tw.h6`text-lg`;
const ComponentPreviewLink = tw.a`mt-4 sm:mt-0 text-primary-500 hocus:text-primary-900 transition duration-300 font-semibold flex items-center`;
const ComponentContent = tw.div`flex justify-between overflow-hidden rounded-b-lg bg-gray-600 relative`;
const ResizableBox = styled(Rnd)`
  ${tw`relative! bg-white pr-4`}
  .resizeHandleWrapper > div {
    ${tw`w-4! right-0!`}
  }
`;
const ResizeHandleButton = tw.button`cursor-col-resize focus:outline-none w-4 border-l bg-gray-100 absolute right-0 inset-y-0`;

export default ({
  features = null,
  primaryButtonUrl = "/form",
  primaryButtonText = "CLAIM YOUR FREE TEST KIT",
  secondaryButtonUrl = "#componentDemos",
  secondaryButtonText = "View Components",
  buttonRoundedCss = "",
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  blocks = components.blocks,
  heading = "DELIVERED AT NO COST TO YOU!",
  description = "Under the current Public Health Emergency, Your Health Insurance and Medicare will cover at-home COVID-19 test kits every month with no out-of-pocket fees to you.",
}) => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */

  const windowSize = useWindowSize();
  useEffect(() => {
    window.gtag("js", new Date());
    window.gtag("config", "UA-45799926-9");
  }, []);

  const scrollRef = useRef(null)


  const executeScroll = () => scrollRef.current.scrollIntoView()    

  const previewImageAnimationVariants = {
    rest: {
      backgroundPositionY: "0%",
    },
    hover: {
      backgroundPositionY: "100%",
      transition: { type: "tween", ease: "linear", duration: 5 },
    },
  };

  const noOfLandingPages = Object.keys(landingPages).length;
  const noOfInnerPages = Object.keys(innerPages).length;
  const noOfComponentBlocks = Object.values(blocks).reduce(
    (acc, block) => acc + Object.keys(block.elements).length,
    0
  );

  features = features || [
    `${noOfLandingPages} Landing Page Demos`,
    `${noOfInnerPages} Inner Pages`,
    `${noOfComponentBlocks} Components`,
    "Uses TailwindCSS",
    "Fully Responsive",
    "Fully Customizable",
  ];
  const [openForm, setopenForm] = React.useState(false);
  const handleOpen = () => {
    setopenForm(true);
  };
  const handleClose = () => {
    setopenForm(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    overflow: "scroll",
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <AnimationRevealPage>
      <Container tw="bg-gray-100 -mx-8 -mt-8 pt-8 px-8">
        <NavRow>
          {/* <LogoLink href="/">
              <img src={logo} alt="Logo" />
            </LogoLink> */}
          <div
            style={{
              display: "flex",
              alignItems:'center',
              justifyContent: "space-between",
              borderBottomWidth:2,
              borderBottomColor:'#DC3545',
              width:'100%',
            }}
          >
            <LogoLink href="/">
            <img src={logo} style={{ width: 200, marginBottom:30 }} />
            </LogoLink>
            <div>
          <NavLink href="#/why-us">Why Us?</NavLink>
          <NavLink href="https://streamcovidtest.com/#">About Us</NavLink>
          <Button style={{color:'#4a5568'}} onClick={()=>{executeScroll()}}>Contact Us</Button>
          </div>
          </div>
        </NavRow>
        <HeroRowMain>
          <div style={{marginLeft:20}}>
          <TextColumn>
            <Heading as="h1">{heading}</Heading>
            <Description style={{ paddingBottom: 25 }}>
              {description}
            </Description>
            <Link to={"/form"}>
              <PrimaryNavLink target="_blank" href="/form">
                CLAIM YOUR TEST KITS
              </PrimaryNavLink>
            </Link>
          </TextColumn>
          </div>
          <ImageColumn>
            <ImageContainer>
              <Image src={heroScreenshotImageSrc} />
            </ImageContainer>
          </ImageColumn>
        </HeroRowMain>
        <AnimationRevealPage>
          <DownloadApp onClick={() => handleOpen} />
        </AnimationRevealPage>
        <AnimationRevealPage>
          <ThreePlans />
        </AnimationRevealPage>
        <AnimationRevealPage>
          <BackgroundAsImage />
        </AnimationRevealPage>
        <AnimationRevealPage>
          <HeroRowMain>
            <ImageColumn>
              <ImageContainer>
                <Image src={heroScreenshotImageSrc} />
              </ImageContainer>
            </ImageColumn>
            <TextColumn>
              <Heading as="h1">{"INDICAID® Test Kit"}</Heading>
              <Description>{"COVID-19 Antigen Rapid Test"}</Description>
              <ul style={{ marginTop: 10, marginBottom: 20 }}>
                <li>-> Self Collection Kit</li>
                <li>-> Result in 15 Minutes</li>
                <li>-> 8 Test monthly*</li>
              </ul>
              <Link to={"/form"}>
                <PrimaryNavLink target="_blank" href="/form">
                  INSTRUCTIONS
                </PrimaryNavLink>
              </Link>
            </TextColumn>
          </HeroRowMain>
          <HeroRow>
            <Link to={"/form"}>
              <PrimaryNavLink>-> Order Now</PrimaryNavLink>
            </Link>
            <div>
              <p style={{ margin: 15 }}>
                *This initiative covers up to (8) over-the-counter
                <br /> COVID-19 tests each calendar month. If you’ve gotten
                eight of these tests in the current calendar month, you will
                need to wait until the beginning of the next calendar month to
                get more tests.
              </p>
            </div>
          </HeroRow>
        </AnimationRevealPage>
        <AnimationRevealPage>
          <SingleCol />
        </AnimationRevealPage>
        <MiniCenteredFooter />
        <div ref={scrollRef}></div>
      </Container>
    </AnimationRevealPage>
  );
};
