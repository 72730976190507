import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center text-primary-900`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;



export default ({
  subheading = "FAQS",
  heading = "You have Questions ?",
  description = "And we have got answers to all of them.",
  faqs = [
    {
      question: "Who's eligible?",
      answer:
        "Medicare will cover these tests if you have Part B, including those enrolled in a Medicare Advantage plan. If you are in a Medicare Advantage plan, the tests covered under this initiative will be covered outside of your existing plan's coverage, and in addition to any over-the-counter tests that may be covered under the plan as a supplemental benefit. Medicare won't cover over-the-counter COVID-19 tests if you only have Medicare Part A (Hospital Insurance) coverage, but you may be able to get free tests through other programs."
    },
    {
      question: "When does this initiative start?",
      answer:
        "It starts April 4, 2022, and continues until the COVID-19 public health emergency (PHE) ends. People with Medicare Part B can get up to eight free over-the-counter tests for the month of April any time before April 30, and can then get another set of eight free over-the-counter tests during each subsequent calendar month through the end of the COVID-19 PHE."
    },
    {
      question: "How can I get test through this initiative?",
      answer:
        "You can get over-the-counter COVID-19 tests at any pharmacy or health care provider that participates in this initiative. Check with your pharmacy or health care provider to see if they are participating. If so, they can provide your tests and will bill Medicare on your behalf. A partial list of participating pharmacies can be found at https://www.medicare.gov/medicare-coronavirus. You should bring your red, white, and blue Medicare card to get your free tests (even if you have a Medicare Advantage Plan or Medicare Part D plan), but the pharmacy may be able to get the information it needs to bill Medicare without the card"
    },
    {
      question: "Do I have to change pharmacies to get a free test?",
      answer:
        "No. You can get your free over-the-counter COVID-19 tests from any eligible pharmacy or health care provider that voluntarily participates in this initiative, even if you aren't a current customer or patient. Getting your tests won't impact any prescriptions you have in place."
    },
    {
      question: "Will I have to pay anything to get over-the-counter COVID-19 tests in this initiative?",
      answer:
        "No, you won't have to pay as long as you go to an eligible pharmacy or health care provider that participates in this initiative. However, if you get more than the eight covered over-the-counter COVID-19 tests in any calendar month, Medicare will not pay for additional over-the-counter tests in that month. You may be responsible for the cost of additional tests that calendar month, unless you have additional health coverage. If have additional coverage, you should check whether they will cover any additional tests obtained beyond the Medicare quantity limit. This means that the pharmacy or health care provider might ask you to pay for them. Note tests are sometimes packaged with more than one test per box, so eight tests may come in fewer than eight boxes."
    },
    {
      question: "Can I get reimbursed for any tests I bought before April 4, 2022?",
      answer:
        "No. Medicare coverage and payment begins on April 4, 2022, and is available for up to eight over-the-counter COVID-19 tests per calendar month you receive from a participating pharmacy or health care provider after the initiative starts. Medicare will not provide payment for over-the-counter COVID-19 tests obtained prior to April 4, 2022."
    },
    {
      question: "Do I have to wait a certain amount of time before I can get another eight over-the...",
      answer:
        "Starting April 4, 2022, this initiative covers up to eight over-the-counter COVID-19 tests each calendar month. If you've gotten eight of these tests in the current calendar month, you will need to wait until the beginning of the next calendar month to get more tests. For example, if you receive eight over-the-counter COVID-19 tests on April 14, 2022, through this initiative, you will not be eligible for another round of eight free over-the-counter COVID-19 tests until May 1, 2022. Note that there can be multiple tests per box, so eight tests may come in fewer than eight boxes."
    },
    {
      question: "Can I submit a claim for a test I pay for myself?",
      answer:
        "No. Medicare cannot process a claim submitted by a beneficiary for a COVID-19 over-the-counter test. You should check whether your pharmacy or health care provider is participating in the initiative, in which case they will bill Medicare for the over-the-counter test on your behalf."
    },
  ]
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1/>
      <DecoratorBlob2 />
    </Container>
  );
};
