import React,{useRef} from "react";
import { Button } from "@mui/material";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import logo from "../images/demo/logo192.png";
import { LogoLink } from "components/headers/light";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import MainFeature1 from "components/features/TwoColWithButton.js";
// import Header from "components/headers/light.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import Features from "components/features/ThreeColSimple.js";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1  mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;

// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
// import Features from "components/features/ThreeColWithSideImage.js";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {

  const scrollRef = useRef(null)


  const executeScroll = () => scrollRef.current.scrollIntoView()

  return (
    <AnimationRevealPage>
      {/* <Header /> */}
      <NavRow>
        {/* <LogoLink href="/">
              <img src={logo} alt="Logo" />
            </LogoLink> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottomWidth: 2,
            borderBottomColor: "#DC3545",
            width: "100%",
          }}
        >
          <LogoLink href="/">
            <img src={logo} style={{ width: 200, marginBottom: 30 }} />
            </LogoLink>
          <div>
            <NavLink href="#/why-us">Why Us?</NavLink>
            <NavLink href="https://streamcovidtest.com/#">About Us</NavLink>
            <Button style={{color:'#4a5568'}} onClick={()=>{executeScroll()}}>Contact Us</Button>
          </div>
        </div>
      </NavRow>
      <div style={{display:'flex',justifyContent:'center',margin:10}}><h1 style={{fontSize:48,fontWeight:'bold'}}>Why Us?</h1></div>
      <MainFeature1
        // subheading={<Subheading>About Treact</Subheading>}
        heading="1. We are In Stock & Ready to Ship"
        description="Unlike other suppliers, we work directly with Covid-19 test manufacturers and purchase in bulk. This enables us to always be in stock and ready to ship out your order. If we don’t have it, nobody else will. Our current lead times are 3-7 business days."
        imageSrc="https://actercarton.com/wp-content/uploads/2020/02/1.jpg"
      />
      <MainFeature1
        // subheading={<Subheading>Our Vision</Subheading>}
        heading="2. Premium Customer Service"
        description="We've invested in friendly customer support staff to quickly and efficiently answer your questions. Whether you need help ordering, have concerns or simply need help using the tests, we're here for you. You can order online, email or give us a call at 773-570-5661"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/photo-1626863905121-3b0c0ed7b94c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
        textOnLeft={false}
      />

      <MainFeature1
        // subheading={<Subheading>Our Vision</Subheading>}
        heading="3. Our Commitment to Privacy & Security"
        description="We respect your right to privacy and would never sell or share your personal information. We only store the minimum information necessary to fulfill your order. In accordance with HIPAA compliance you always have access to the data we collect and can request it at any time."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://cdn.pixabay.com/photo/2020/05/24/09/51/security-5213398_960_720.jpg"
      />
      <MiniCenteredFooter/>
      <div ref={scrollRef}></div>
    </AnimationRevealPage>
  );
};
