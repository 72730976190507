import React from "react";
import "./Hero.css";
import useWindowSize from "./utils/useWindowSize";

function HeroArea() {
  const windowSize = useWindowSize();

  return (
    <>
      <div
        style={{
          justifyContent: "center",
          alignItems:'center',
          display: "flex",
        }}
      >
        <div
          style={{
            width: windowSize.width * 0.8,
            height: windowSize.height*0.1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src="logo192.png" style={{width:200,paddingTop:50,paddingBottom:50}}/>
        </div>
      </div>
    </>
  );
}

export default HeroArea;
